<template>
  <validation-observer
    ref="allowanceSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="allowanceSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm trợ cấp sư phạm' : 'Cập nhật trợ cấp sư phạm'"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên trợ cấp sư phạm <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tên trợ cấp sư phạm"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetAllowance.name"
              name="name"
              placeholder="Nhập tên trợ cấp sư phạm"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="code">
          <template v-slot:label>
            Mã trợ cấp sư phạm <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mã trợ cấp sư phạm"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetAllowance.code"
              name="code"
              placeholder="Nhập mã trợ cấp sư phạm"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetAllowance.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="orderNo">
          <template v-slot:label>
            Thứ tự <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Thứ tự"
            rules="required"
          >
            <b-form-input
              id="orderNo"
              v-model="targetAllowance.orderNo"
              name="orderNo"
              placeholder="Nhập thứ tự"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('allowanceSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'AllowanceSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    allowance: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetAllowance: {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      },
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      required,
    }
  },
  computed: {
    ...mapGetters({
    }),
    isCreated() {
      return !this.allowance
    },
  },
  methods: {
    ...mapActions({
      createAllowance: 'allowance/createAllowance',
      updateAllowance: 'allowance/updateAllowance',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.allowance) {
        this.targetAllowance = { ...this.allowance }
      }
    },
    onHide() {
      this.$refs
        .allowanceSaveFormRef
        .reset()
      this.targetAllowance = {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .allowanceSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createAllowance(this.targetAllowance)
            : await this.updateAllowance(this.targetAllowance)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('allowanceSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
